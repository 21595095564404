.pw-login-input{
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid grey;
  outline:0;
  font-size: 1.1rem;
}
.pw-login-submit{
  background-color:#59CE90;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.pw-login-input:focus{
  outline: 0;
  border: 2px solid #59CE90;
  padding:9px;
}
.pw-password-icon {
  position:absolute;
  right:10px;
  height:25px;
  top:10px;
  opacity:0.6;
  cursor: pointer;
}

.gm-sessions-dialog .MuiDialog-paperWidthSm {
  max-width: 50vw !important;
  width: 50vw !important;
}

.gm-sessions-div {
  max-width: 50vw;
  height: 30vh;
  padding: 2rem;
}

.gm-header-text {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 12px;
}

.gm-header-disc {
  font-weight: bold;
  font-size: 16px;
  color: gray !important;
  margin-bottom: 28px;
}

.gm-session-div {
  padding-left: 12px ;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.gm-session-top-row {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 4px;
}

.gm-session-bottom-row {
  margin-bottom: 4px;
  font-size: 12px;
}

.gm-session-date {
  font-weight: bold;
  font-size: 14px;
}

.gm-session-logout {
  color: rgb(219, 55, 55);
  font-size: 12px;
  cursor: pointer;
}

.gm-buttons-div {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: absolute;
  right: 5%;
  bottom: 5%;
}

.gm-cancel-button {
  width: 6rem;
  height: 2rem;
  border: 1px solid lightgray;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}


@media screen and (max-width:760px) {
  .gm-sessions-dialog .MuiDialog-paperWidthSm {
    max-width: 60vw !important;
    width: 60vw !important;   
    padding-right: 10px;
    padding-bottom: 10px;
  }
  
  .gm-header-text, .gm-header-disc, .gm-session-div{
    width: 100%;
    padding: 5px;
  }

  .gm-header-text {
    font-size: 12px;
    justify-content: center;
    margin-bottom: 10px;
  }

  .gm-header-disc {
    font-size: 10px;
    margin-bottom: 15px;
  }

  .gm-session-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gm-session-top-row {
    display: flex;
    flex-direction: row;
    align-items: start;
    width: 100%;
  }

  .gm-session-bottom-row {
    font-size: 8px;
    width: 100%;
  }
  
  .gm-session-date {
    font-size: 10px;
    width: 100%;
    padding-right: 20px;
  }

  .gm-buttons-div {
    position: relative;
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .gm-cancel-button {
    width: 4.5rem;
    height: 1.5rem;
  }
}