.pw-login-input{
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid grey;
  outline:0;
  font-size: 1.1rem;
}
.pw-login-submit{
  background-color:#59CE90;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.pw-login-input:focus{
  outline: 0;
  border: 2px solid #59CE90;
  padding:9px;
}
.pw-password-icon {
  position:absolute;
  right:10px;
  height:25px;
  top:10px;
  opacity:0.6;
  cursor: pointer;
}
label{
  color: gray;
  font-size: 0.9rem;
}