*{
  font-family: "Inter";
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gm-input{
  font-size: 1.02rem;
  border: 1px solid #E6E6E6;
  margin-top: 15px;
}
.gm-sample-message{
  background:white;
  border-radius:25px;
  padding:15px;
  cursor: pointer;
  font-size: 1.02rem;
}

input:focus{
  outline:2.5px solid #59CE90;
  border:2px solid transparent;
}
table{
  border-collapse: collapse !important;
}
td,th{
  border: 1px solid black !important;
  padding: 10px !important;
}